import { stepIteration } from './stepIteration';

export function minutesToHM(minutes = 0, hours = 0) {
  return {
    h: Math.trunc(minutes / 60) + hours,
    m: minutes % 60
  }
}

export function hmToTMinutes(minutes = 0, hours = 0) {
  return hours * 60 + minutes;
}

function addZero(v: number): string {
  return v < 10 ? `0${v}` : `${v}`;
}

export function getTime(minutes: number) {
  const { h, m } = minutesToHM(minutes);
  return `${addZero(h)}:${addZero(m)}`;
}

export function getTimeOptions(start = 0, end = 1440, step = 15) {
  return stepIteration(start, end, step, (value) => ({ value, label: getTime(value) }));
}
