import type { Ref } from 'vue';
import { useCompanyStore } from '../stores/company';

export function usePriceFormatter(currency?: Ref<string>) {
  const { $i18n } = useNuxtApp();
  const { locale } = $i18n as ReturnType<typeof useI18n>;
  const store = useCompanyStore();

  function getCurrency() {
    // return unref(currency) || store.company.currency;
    return store.company.currency || unref(currency);
  }

  const formatter = computed(() => {
    const currency = getCurrency();
    return new Intl.NumberFormat(currency === 'RUB' ? 'ru' : locale.value || locale.value, { style: 'currency', currency, minimumFractionDigits: 0 })
  });

  function formatPrice(value: string | number) {
    return formatter.value?.format(+value);
  }

  return {
    formatPrice,
    getCurrency,
  };
}